<template>
  <div>
    <v-overlay v-if="loading">
      <Lottie
        :options="defaultOptions"
        :height="300"
        :width="400"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>
    <v-container class="px-0 px-md-12 screen-width" v-if="!loading">
      <v-row>
        <v-col>
          <v-hover>
            <div class="button-wrapper">
              <v-btn
                color="primary"
                absolute
                top
                right
                fab
                :small="$vuetify.breakpoint.mdAndDown"
                elevation="2"
                @click="$router.back()"
              >
                <v-icon color="white">fas fa-chevron-left</v-icon>
              </v-btn>
            </div>
          </v-hover>
          <div
            id="businessName"
            class="text-h4 text-md-h3 mt-n0  text-center text--primary"
          >
            {{ businessStore.shortName }}
          </div>
        </v-col>
      </v-row>
      <transition-group
        leave-active-class="animated bounceOutLeft"
        tag="section"
      >
        <div v-for="(item, idx) in cart" :key="item.randomKey">
          <v-divider light class="mx-12"></v-divider>
          <v-row align="center" justify="center">
            <v-col cols="4" md="2" class="text-center">
              <v-badge overlap :content="item.quantity">
                <img
                  :src="item.images[0] || ochoLogoPlaceholder"
                  alt="producto"
                  class="rounded-xl"
                  :class="
                    $vuetify.breakpoint.mdAndDown ? 'smallImg' : 'largeImg'
                  "
                  data-aos="fade-down"
                />
              </v-badge>
            </v-col>
            <v-col cols="8" md="7">
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <span class="text-body-1 text-md-h6">
                    {{ item.name }}
                    <span>({{ item.subTotalPrice | toCurrency }})</span>
                  </span>
                  <div
                    v-for="(addOn, idx) in item.addOns"
                    :key="addOn.id + idx"
                  >
                    <div v-if="!Array.isArray(addOn.selectedAddOnProduct)">
                      <span
                        class="text-body-2 text-md-subtitle-1 grey--text
                    text--darken-2"
                      >
                        {{ productDetailName(addOn.selectedAddOnProduct) }}
                        <span v-if="addOn.price > 0" class="text-md-subtitle-2">
                          {{ addOn.price | toCurrency }}
                        </span>
                      </span>
                    </div>

                    <div v-else>
                      <div
                        v-for="(product, idx) in addOn.selectedAddOnProduct"
                        :key="product + idx + 'selectedAddOnProduct'"
                      >
                        <span
                          class="text-body-2 text-md-subtitle-1 grey--text text--darken-2"
                        >
                          {{ productDetailName(product.id || product) }}
                          <span
                            v-if="addOn.price > 0"
                            class="text-md-subtitle-2"
                          >
                            ({{ addOn.price | toCurrency }})
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="3"
              class="d-flex justify-space-around flex-md-column align-md-center align-md-tretch px-0 px-md-12"
            >
              <v-chip
                color="primary"
                class="my-1 text-center px-5"
                outlined
                @click="deleteItem(item, idx)"
              >
                Eliminar
              </v-chip>
              <v-chip
                color="primary"
                class="my-1 text-center px-5"
                @click="editItem(item, idx)"
              >
                Editar
              </v-chip>
            </v-col>
          </v-row>
        </div>
      </transition-group>

      <div class="subtotal-wrapper pb-6">
        <v-row align="center" justify="center">
          <v-col cols="6" class="text-left text-md-center pl-6 py-0">
            <span class="text-subtitle-1 text-md-h6 grey--text text--darken-4"
              >Propina</span
            >
          </v-col>
          <v-col cols="6" class="d-flex justify-center mb-5">
            <v-chip-group mandatory center-active active-class="primary--text">
              <v-chip
                :small="$vuetify.breakpoint.mdAndDown"
                @click="handleTip(0)"
              >
                0%
              </v-chip>
              <v-chip
                :small="$vuetify.breakpoint.mdAndDown"
                @click="handleTip(10)"
              >
                10%
              </v-chip>
              <v-chip
                :small="$vuetify.breakpoint.mdAndDown"
                @click="handleTip(15)"
              >
                15%
              </v-chip>
            </v-chip-group>
          </v-col>

          <v-col cols="6" class="text-left text-md-center pl-6 py-0">
            <span class="text-subtitle-2 text-md-subtitle-1 grey--text">
              Sub total
            </span>
          </v-col>
          <v-col cols="6" class="text-right text-md-center pr-6 py-0">
            <span class="text-subtitle-2 text-md-subtitle-1 grey--text">
              {{ cartSubTotal | toCurrency }}
            </span>
          </v-col>
          <v-col cols="6" class="text-left text-md-center pl-6 py-0">
            <span class="text-subtitle-2 text-md-subtitle-1 grey--text">
              Propina
            </span>
          </v-col>
          <v-col cols="6" class="text-right text-md-center pr-6 py-0">
            <span class="text-subtitle-2 text-md-subtitle-1 grey--text">
              {{ tip | toCurrency }}
            </span>
          </v-col>

          <v-col cols="6" class="text-left text-md-center pl-6 py-0 mt-6">
            <span class="text-h6 text-md-h5 grey--text text--darken-4">
              Total
            </span>
          </v-col>
          <v-col cols="6" class="text-right text-md-center pr-6 py-0 mt-6">
            <span class="text-h6 text-md-h5 grey--text text--darken-4">
              {{ total | toCurrency }}
            </span>
          </v-col>
        </v-row>
      </div>
      <SubmitForm :business="businessStore" :cart="cart" />
    </v-container>
  </div>
</template>

<script>
import AOS from "aos";
import { mapState } from "vuex";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import SubmitForm from "@/components/SubmitForm";

export default {
  name: "Checkout",
  components: {
    Lottie,
    SubmitForm,
  },
  mixins: [lottieSettings],
  computed: {
    ...mapState([
      "cart",
      "businessStore",
      "productsStore",
      "addOnsProductStore",
    ]),
    cartSubTotal() {
      return this.$store.getters.cartTotal;
    },
    cartItemCount() {
      return this.$store.getters.cartItemCount;
    },
    total() {
      return this.cartSubTotal + this.tip;
    },
  },
  data() {
    return {
      loading: true,
      tip: 0,
      products: [],
      ochoLogoPlaceholder:
        "https://firebasestorage.googleapis.com/v0/b/el-ocho-data/o/product-default.jpeg?alt=media&token=063d2780-9ef0-4bce-bab6-aac516fe6d28",
    };
  },
  mounted() {
    AOS.init({ once: true });
    if (this.cartItemCount == 0) this.$router.back();
    this.addRandomKey();
    // await this.getAddOnProducts();
    this.$store.state.visibleSearch = false;
    this.loading = false;
  },
  methods: {
    deleteItem(item, idx) {
      if (confirm(`¿Estás seguro de eliminar ${item.name}?`)) {
        this.$store.commit("removeFromCart", idx);
        if (this.cartItemCount === 0) this.$router.back();
      }
    },
    editItem(item, idx) {
      this.$router.push({
        name: "Product",
        params: { product: item, edit: true, idx: idx },
      });
    },
    addRandomKey() {
      this.cart.forEach((item) => {
        item.randomKey = this.generateRandomKey();
      });
    },
    generateRandomKey() {
      let length = 10;
      return Math.round(
        Math.pow(36, length + 1) - Math.random() * Math.pow(36, length)
      )
        .toString(36)
        .slice(1);
    },
    // getAddOnProducts() {
    //   if (this.products.length == 0) {
    //     this.cart.forEach((item) => {
    //       item.addOns.forEach((addOn) => {
    //         fb.db
    //           .collection(`addons/${addOn.id}/products`)
    //           .get()
    //           .then((snapshot) => {
    //             snapshot.docs.forEach(async (doc) => {
    //               await this.getProducts(doc.data());
    //             });
    //           })
    //           .catch((error) => {
    //             console.log("Error getting document:", error);
    //           });
    //       });
    //     });
    //   }
    // },
    // getProducts(product) {
    //   fb.db
    //     .collection("products")
    //     .doc(product.productId)
    //     .get()
    //     .then((doc) => {
    //       if (doc.exists) {
    //         this.products.push(
    //           Object.assign({ id: doc.id, fromAddOn: product }, doc.data())
    //         );
    //       } else {
    //         console.log("No existe!");
    //       }
    //     });
    // },
    productDetailName(id) {
      let product = this.addOnsProductStore.find(
        (product) => product.id === id
      );
      if (product) {
        return product.name;
      } else {
        return "";
      }
    },
    handleTip(amount = 0) {
      this.tip = this.$store.getters.cartTotal * (amount / 100);
    },
  },
  filters: {
    toCurrency(value) {
      if (typeof value !== "number") return value;
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });

      return formatter.format(value);
    },
  },
};
</script>

<style scoped src="aos/dist/aos.css"></style>
<style scoped>
.smallImg {
  height: 80px;
  width: 80px;
  object-fit: cover;
  transition: all 0.3s ease;
}
.largeImg {
  height: 100px;
  width: 100px;
  object-fit: cover;
  transition: all 0.3s ease;
}
.subtotal-wrapper {
  border-radius: 10px;
  background-color: #fff3e0;
  min-height: 5em;
  margin: 10px 5px;
}
.button-wrapper {
  position: absolute;
  margin-top: 1em;
  margin-left: 7.5em;
  opacity: 0.7;
}
@media only screen and (max-width: 600px) {
  .button-wrapper {
    margin-left: 4.5em;
  }
}
</style>
