<template>
  <v-card elevation="1" class="pa-6 mx-2 mt-10">
    <form>
      <v-row justify="space-around">
        <v-col cols="12">
          <v-textarea
            v-model.trim="userData.instructions"
            label="Instrucciones"
            outlined
            rounded
            auto-grow
          ></v-textarea>
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model.trim="userData.name"
            :error-messages="nameErrors"
            label="Nombre"
            required
            outlined
            rounded
            @input="$v.userData.name.$touch()"
            @blur="$v.userData.name.$touch()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model.trim="userData.email"
            :error-messages="emailErrors"
            label="E-mail"
            required
            outlined
            rounded
            @input="$v.userData.email.$touch()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model.trim="userData.phone"
            :error-messages="phoneErrors"
            label="Teléfono"
            v-mask="'####-####'"
            outlined
            rounded
            @input="$v.userData.phone.$touch()"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            v-model.trim="userData.creditCardName"
            :error-messages="creditCardNameErrors"
            label="Nombre en tarjeta"
            autocomplete="off"
            outlined
            rounded
            @input="$v.userData.creditCardName.$touch()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="userData.creditCard"
            :error-messages="creditCardErrors"
            label="Tarjeta de crédito"
            autocomplete="off"
            outlined
            rounded
            :append-icon="creditCardIcon"
            v-mask="generateCardNumberMask"
            @input="$v.userData.creditCard.$touch()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="userData.creditCardExpMonth"
            :error-messages="creditCardExpMonthErrors"
            :items="months"
            label="Mes que expira"
            outlined
            rounded
            required
            @input="$v.userData.creditCardExpMonth.$touch()"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="userData.creditCardExpYear"
            :error-messages="creditCardExpYearErrors"
            :items="years"
            label="Año que expira"
            outlined
            rounded
            required
            @input="$v.userData.creditCardExpYear.$touch()"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="userData.creditCardCCV"
            :error-messages="creditCardCCVErrors"
            label="CCV"
            v-mask="'####'"
            outlined
            rounded
            required
            @input="$v.userData.creditCardCCV.$touch()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider class="mb-6" />
      <v-row align="center" justify="space-around" class="px-md-8">
        <v-col cols="6">
          <v-btn large block outlined rounded @click="clear" color="primary">
            Borrar
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn large block rounded @click="submit" color="primary">
            Finalizar
          </v-btn>
        </v-col>
      </v-row>
    </form>
  </v-card>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  email,
  minLength,
  maxLength,
  helpers,
  between,
  numeric,
} from "vuelidate/lib/validators";
const spaceNumeric = helpers.regex("spaceNumeric", /^[0-9\s]*$/);

export default {
  name: "SubmitForm",
  mixins: [validationMixin],
  validations: {
    userData: {
      name: { required },
      email: { required, email },
      phone: { minLength: minLength(9) },
      creditCard: { required, spaceNumeric },
      creditCardName: { required, minLength: minLength(3) },
      creditCardExpMonth: { required, between: between(1, 12) },
      creditCardExpYear: { required },
      creditCardCCV: {
        required,
        numeric,
        minLength: minLength(3),
        maxLength: maxLength(4),
      },
    },
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
    cart: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      userData: {
        name: "",
        email: "",
        phone: "",
        instructions: "",
        creditCard: "",
        creditCardName: "",
        creditCardExpMonth: "",
        creditCardExpYear: "",
        creditCardCCV: "",
      },
      tempBusiness: this.business.id,
      city: this.business.city,
      tin: this.business.tin,
      amexCardMask: "#### ###### #####",
      otherCardMask: "#### #### #### ####",
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      minCardYear: new Date().getFullYear(),
    };
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.userData.name.$dirty) return errors;
      !this.$v.userData.name.required && errors.push("Nombre es requerido");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.userData.email.$dirty) return errors;
      !this.$v.userData.email.email && errors.push("ingresar un correo válido");
      !this.$v.userData.email.required && errors.push("e-mail es requerido");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.userData.phone.$dirty) return errors;
      !this.$v.userData.phone.minLength &&
        errors.push("ingresar número valido");
      return errors;
    },
    creditCardErrors() {
      const errors = [];
      if (!this.$v.userData.creditCard.$dirty) return errors;
      !this.$v.userData.creditCard.spaceNumeric &&
        errors.push("ingresar número de tarjeta válido");
      !this.$v.userData.creditCard.required &&
        errors.push("tarjeta es requerida");
      return errors;
    },
    creditCardNameErrors() {
      const errors = [];
      if (!this.$v.userData.creditCardName.$dirty) return errors;
      !this.$v.userData.creditCardName.minLength &&
        errors.push("mínimo 3 caracteres");

      !this.$v.userData.creditCardName.required &&
        errors.push("nombre es requerido");
      return errors;
    },
    creditCardExpMonthErrors() {
      const errors = [];
      if (!this.$v.userData.creditCardExpMonth.$dirty) return errors;

      !this.$v.userData.creditCardExpMonth.required &&
        errors.push("seleccionar mes");

      !this.$v.userData.creditCardExpMonth.between &&
        errors.push("mes tiene que ser válido");
      return errors;
    },
    creditCardExpYearErrors() {
      const errors = [];
      if (!this.$v.userData.creditCardExpYear.$dirty) return errors;
      !this.$v.userData.creditCardExpYear.required &&
        errors.push("seleccionar año");
      return errors;
    },
    creditCardCCVErrors() {
      const errors = [];
      if (!this.$v.userData.creditCardCCV.$dirty) return errors;
      !this.$v.userData.creditCardCCV.required && errors.push("ingresar CCV");
      !this.$v.userData.creditCardCCV.numeric &&
        errors.push("ingresar CCV válido");
      !this.$v.userData.creditCardCCV.minLength &&
        errors.push("mínimo 3 números");
      !this.$v.userData.creditCardCCV.maxLength &&
        errors.push("máximo 4 números");
      return errors;
    },
    years() {
      let yearArray = [];
      for (var i = this.minCardYear; i <= this.minCardYear + 12; i++) {
        yearArray.push(i);
      }
      return yearArray;
    },
    getCardType() {
      let number = this.userData.creditCard;
      if (number) {
        let re = new RegExp("^4");
        if (number.match(re) != null) return "visa";

        re = new RegExp("^(34|37)");
        if (number.match(re) != null) return "amex";

        re = new RegExp("^5[1-5]");
        if (number.match(re) != null) return "mastercard";

        re = new RegExp("^6011");
        if (number.match(re) != null) return "discover";

        re = new RegExp("^9792");
        if (number.match(re) != null) return "troy";

        return "visa"; // default type
      } else {
        return null;
      }
    },
    generateCardNumberMask() {
      return this.getCardType === "amex"
        ? this.amexCardMask
        : this.otherCardMask;
    },
    creditCardIcon() {
      switch (this.getCardType) {
        case "visa":
          return "fab fa-cc-visa";
        case "amex":
          return "fab fa-cc-amex";
        case "mastercard":
          return "fab fa-cc-mastercard";
        case "discover":
          return "fab fa-cc-discover";
        default:
          return "";
      }
    },
    minCardMonth() {
      if (this.userData.creditCardExpYear === this.minCardYear)
        return new Date().getMonth() + 1;
      return 1;
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.userData.$invalid) return;
      this.processCheckOut();
    },
    clear() {
      this.$v.$reset();
      this.userData.name = "";
      this.userData.email = "";
      this.userData.phone = "";
      this.userData.instructions = "";
      this.userData.creditCard = "";
      this.userData.creditCardName = "";
      this.userData.creditCardExpMonth = "";
      this.userData.creditCardExpYear = "";
      this.userData.creditCardCCV = "";
    },
    processCheckOut() {
      let _this = this;

      this.$swal({
        icon: "success",
        title: "¡Muchas gracias por tu compra!",
        text: "Le enviaremos un correo con su factura (o algo diferente)",
        timer: 5000,
        timerProgressBar: true,
      });

      setTimeout(function() {
        _this.clear();
        _this.$store.commit("clearCart");
        _this.$router.push({
          path: `/business/${_this.business.webName}/${_this.business.geoAddress.geohash}`,
        });
      }, 500);
    },
  },
  watch: {
    "userData.creditCardExpYear"() {
      if (this.userData.creditCardExpMonth < this.minCardMonth) {
        this.userData.creditCardExpMonth = "";
      }
    },
  },
};
</script>
